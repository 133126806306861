module.exports={
  "loadingTitle":                  "Please wait...",
  "close":                         "Close",
  "windowsAuthTitle":              "Windows Authentication",
  "invalid":                       "Invalid",
  "mustMatch":                     "Must match",
  "loginSocialButton":             "Login with {connection:title}",
  "signupSocialButton":             "Signup with {connection:title}",
  "signin": {
    "title":                       "Login",
    "action":                      "Access",
    "actionDomain":                "Login to {domain}",
    "all":                         "Show all",
    "strategyEmailEmpty":          "The email is empty.",
    "strategyEmailInvalid":        "The email is invalid.",
    "strategyDomainInvalid":       "The domain {domain} has not been setup.",
    "signupText":                  "Sign Up",
    "forgotText":                  "Reset password",
    "cancelAction":                "Cancel",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Username",
    "passwordPlaceholder":         "Password",
    "separatorText":               "or",
    "serverErrorText":             "There was an error processing the sign in.",
    "returnUserLabel":             "Last time you signed in using...",
    "domainUserLabel":             "You are connected from your corporate network...",
    "wrongEmailPasswordErrorText": "Wrong email or password.",
    "or":                          "... or sign in using",
    "loadingMessage":              "Signing In with {connection}...",
    "popupCredentials":            "Enter your credentials in the pop-up window",
    "userClosedPopup":             "Popup window closed. Try again.",
    "userConsentFailed":           "Permissions were not granted. Try again."
  },
  "signup": {
    "description":                 "",
    "title":                       "Sign Up",
    "action":                      "Sign Up",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "Create a Password",
    "cancelAction":                "Cancel",
    "headerText":                  "Please enter your email and password",
    "footerText":                  "",
    "enterpriseEmailWarningText":  "This domain {domain} has been configured for Single Sign On and you can't create an account. Try signing in instead.",
    "serverErrorText":             "There was an error processing the sign up.",
    "userExistsErrorText":         "The user already exists.",
    "invalidPassword":             "Password is too weak."
  },
  "reset": {
    "title":                       "Reset Password",
    "action":                      "Send",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "New Password",
    "repeatPasswordPlaceholder":   "Confirm New Password",
    "cancelAction":                "Cancel",
    "successText":                 "We've just sent you an email to reset your password.",
    "enterSamePasswordText":       "Please enter the same password.",
    "headerText":                  "Please enter your email and the new password. We will send you an email to confirm the password change.",
    "serverErrorText":             "There was an error processing the reset password.",
    "userDoesNotExistErrorText":   "User does not exist.",
  }
}
