module.exports = (function anonymous(locals, filters, escape, rethrow) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div id="a0-lock" class="a0-lock" dir="auto">\n    <div class="a0-signin">\n        <div class="a0-popup">\n            <div class="a0-overlay">\n                <div class="', escape((5,  locals && locals.options.container ? '' : 'a0-centrix' )), '">\n                    ' + (function(){var buf = [];
 buf.push('<div id="a0-onestep" class="a0-panel a0-onestep a0-free-subscription">\n    ' + (function(){var buf = [];
 buf.push('<div class="a0-header a0-top-header ', escape((1,  locals.embedded ? 'a0-hide' : '')), '">\n\n    <div class="a0-bg-gradient"></div>\n\n    <div class="a0-image ', escape((5,  options.icon ? '' : 'a0-hide' )), '">\n        <img src="', escape((6,  options.icon || '' )), '">\n    </div>\n\n    <div class="a0-avatar ', escape((9,  !options.icon ? '' : 'a0-hide' )), '">\n        <i class="a0-avatar-guest a0-icon-budicon-2"></i>\n    </div>\n\n    <h1>', escape((13,  options.i18n.t('signin:title') )), '</h1>\n    <h2 class="a0-error a0-hide">&nbsp;</h2>\n    <h2 class="a0-success a0-hide">&nbsp;</h2>\n\n    <!-- if standalone, you shouldn\'t be able to close the widget -->\n    <a class="a0-close a0-icon-budicon-3 ', escape((18,  options.closable ? '' : 'a0-hide' )), '" href="#"></a>\n</div>\n');
return buf.join('');})() + '\n\n    <div class="a0-mode-container"></div>\n\n    ' + (function(){var buf = [];
 buf.push('<div class=\'a0-footer\'>\n    <a href="http://auth0.com" target="_new" class="a0-logo">\n        <i class="a0-icon-badge"></i>\n    </a>\n</div>\n');
return buf.join('');})() + '\n</div>\n');
return buf.join('');})() + '\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n'); })();
} 
return buf.join('');
})